var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.informationRequest.value)?_c('div',{staticClass:"information-request-body-confirm"},[_c('div',{staticClass:"registration-confirm-title"},[_c('p',[_vm._v(_vm._s(_vm.getContentBody("information-request-form-confirm-title")))])]),_c('div',{staticClass:"registration-confirm-body"},[_c('p',[_vm._v(_vm._s(_vm.getContentBody("information-request-form-confirm-body")))])])]):_vm._e(),(_vm.informationRequest.error)?_c('div',{staticClass:"information-request-body-error"},[_c('h2',[_vm._v("\n      "+_vm._s(_vm.getContentBody("information-request-form-error-title"))+"\n    ")]),_c('p',[_vm._v(_vm._s(_vm.getContentBody("information-request-form-error-body")))])]):_vm._e(),(_vm.informationRequest.empty || _vm.informationRequest.error)?_c('div',{staticClass:"question-registration-body-form-form"},[_c('input',{attrs:{"id":"origin","type":"hidden"},domProps:{"value":_vm.origin}}),_c('div',{staticClass:"section"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.givenName),expression:"givenName"}],attrs:{"id":"givenName","placeholder":_vm.getContentBody(
            'information-request-form-given-name-field-placeholder'
          ),"type":"text","name":"givenName"},domProps:{"value":(_vm.givenName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.givenName=$event.target.value}}})]),_c('div',{staticClass:"section"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.familyName),expression:"familyName"}],attrs:{"id":"familyName","placeholder":_vm.getContentBody(
            'information-request-form-family-name-field-placeholder'
          ),"type":"text","name":"familyName"},domProps:{"value":(_vm.familyName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.familyName=$event.target.value}}})]),_c('div',{staticClass:"section"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumber),expression:"phoneNumber"}],attrs:{"id":"phoneNumber","placeholder":_vm.getContentBody(
            'information-request-form-phone-number-field-placeholder'
          ),"type":"text","name":"phoneNumber"},domProps:{"value":(_vm.phoneNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phoneNumber=$event.target.value}}}),_c('p',{staticClass:"error",class:{ hidden: _vm.validPhonenumber }},[_c('small',[_vm._v("\n          "+_vm._s(_vm.getContentBody(
              "information-request-form-phone-number-field-error"
            ))+"\n        ")])])]),_c('div',{staticClass:"section"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"id":"email","placeholder":_vm.getContentBody('information-request-form-email-field-placeholder'),"type":"text","name":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('p',{staticClass:"error",class:{ hidden: _vm.validEmail }},[_c('small',[_vm._v("\n          "+_vm._s(_vm.getContentBody("information-request-form-email-field-error"))+"\n        ")])])]),_c('div',{staticClass:"subscribeNewsletter"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.subscribeNewsletter),expression:"subscribeNewsletter"}],attrs:{"id":"subscribeNewsletter","type":"checkbox","name":"subscribeNewsletter"},domProps:{"checked":Array.isArray(_vm.subscribeNewsletter)?_vm._i(_vm.subscribeNewsletter,null)>-1:(_vm.subscribeNewsletter)},on:{"change":function($event){var $$a=_vm.subscribeNewsletter,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.subscribeNewsletter=$$a.concat([$$v]))}else{$$i>-1&&(_vm.subscribeNewsletter=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.subscribeNewsletter=$$c}}}}),_c('label',{attrs:{"for":"subscribeNewsletter"}},[_vm._v("\n        "+_vm._s(_vm.getContentBody("information-request-subscribe-mailing-list"))+"\n      ")])]),_c('div',{staticClass:"button-row"},[_c('button',{attrs:{"disabled":_vm.informationRequest.loading || !_vm.validated},on:{"click":_vm.onClick}},[_vm._v("\n        "+_vm._s(_vm.getContentBody("information-request-form-button-title"))+"\n      ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }