




import Vue from "vue";
import Component from "vue-class-component";
import NotFoundComponent from "@/components/NotFound.vue";
import { Action } from "vuex-class";
import { RootActionTypes } from "@/store/types";

@Component({ components: { NotFoundComponent } })
export default class NotFoundPage extends Vue {
  @Action(RootActionTypes.SetResponseStatus)
  SetResponseStatus!: (status: number) => void;

  // Server-side only
  // This will be called by the server renderer automatically
  async serverPrefetch() {
    // return the Promise from the action
    // so that the component waits before rendering
    return this.SetResponseStatus(404);
  }

  mounted() {
    this.SetResponseStatus(404);
  }
}
