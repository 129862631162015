

























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class VisualProfileSquare extends Vue {
  @Prop({ required: true }) body: string;
  @Prop({ required: true }) imageSrc: string;
  @Prop({ required: true }) active: boolean;
  @Prop({ required: true }) to: any;
}
