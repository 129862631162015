




































































































































































































import { Component, Vue, Mixins, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ContentComponent from "@/components/ContentComponent";

import { ApiRequest } from "@/store/types";
import { namespace } from "vuex-class";
const baseModule = namespace("base");

import VueMarkdown from "vue-markdown";

import { BaseActionTypes, BaseGetterTypes } from "@/store/base/types";
import { PilotRegistration, InformationRequest } from "@/models";
import InformationRequestComponent from "@/components/InformationRequest.vue";

import Logo from "@/assets/logo.svg";
import Tarief from "@/assets/tarief.svg";
import ForWhomSquare from "@/components/ForWhomSquare.vue";
@Component({
  components: {
    Logo,
    Tarief,
    VueMarkdown,
    ForWhomSquare,
    InformationRequestComponent,
  },
})
export default class ForWhom extends Mixins(ContentComponent) {
  constructor() {
    super();
    this.componentName = "forwhom";
  }

  // InformationRequest
  @baseModule.Action(BaseActionTypes.RegisterInformationRequest)
  RegisterInformationRequest!: (request: InformationRequest) => void;

  @baseModule.Action(BaseActionTypes.ClearInformationRequest)
  ClearInformationRequest!: () => void;

  @baseModule.Getter(BaseGetterTypes.InformationRequest)
  informationRequest!: ApiRequest<InformationRequest>;

  form: boolean = false;

  get details() {
    return (
      (this.$store.state.route.meta && this.$store.state.route.meta.for) || ""
    );
  }

  hideForm() {
    this.form = false;
  }

  showForm() {
    this.form = true;
  }

  @Watch("$route")
  hideFormOnRouteChange() {
    this.hideForm();
  }

  mounted() {
    this.hideForm();
    setTimeout(() => this.scrollFix(this.$route.hash), 1);
  }

  scrollFix(hashbang) {
    location.hash = hashbang;
  }
}
