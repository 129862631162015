










































import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import ContentComponent from "@/components/ContentComponent";
import { namespace } from "vuex-class";
const mentorModule = namespace("mentors");
import { MentorActionTypes, MentorGetterTypes } from "@/store/mentors/types";

import { ApiRequest } from "@/store/types";
import { Content, Mentor } from "@/models";

import VueMarkdown from "vue-markdown";
import WhatsApp from "@/components/WhatsAppComponent.vue";

@Component({
  components: {
    VueMarkdown,
    WhatsApp,
  },
})
export default class ContentPage extends Mixins(ContentComponent) {
  constructor() {
    super();
    this.componentName = this.getComponentName();
  }

  mounted() {
    this.setComponentName();
  }

  @Watch("$route")
  setComponentName() {
    this.componentName = this.getComponentName();
    this.LoadContents(this.componentName);
  }

  getComponentName() {
    if (this.$route && (this.$route.params.page || this.$route.name)) {
      return this.$route.params.page || this.$route.name;
    }
    return "";
  }
}
