
























































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Mentor } from "@/models";

import MeerZien from "@/assets/meerzien.svg";
import Reviews from "@/assets/review.svg";
import Sessies from "@/assets/sessies.svg";
import Euro from "@/assets/euro.svg";
import Phone from "@/assets/telefoon-12.svg";

import ContentComponent from "./ContentComponent";
import { mixins } from "vue-class-component";

@Component({
  components: { MeerZien, Euro, Reviews, Sessies, Phone },
})
export default class MentorCard extends mixins(ContentComponent) {
  @Prop() mentor!: Mentor;

  constructor() {
    super();
    this.componentName = "mentor card";
  }
}
