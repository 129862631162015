









































import { Component, Vue, Mixins } from "vue-property-decorator";
import ContentComponent from "@/components/ContentComponent";

import { namespace } from "vuex-class";
const baseModule = namespace("base");

import { ApiRequest } from "@/store/types";
import { BaseActionTypes, BaseGetterTypes } from "@/store/base/types";
import { PilotRegistration } from "@/models";

import Logo from "@/assets/logo.svg";
import VueMarkdown from "vue-markdown";
import PilotRegistrationComponent from "@/components/PilotRegistration.vue";

@Component({ components: { Logo, VueMarkdown, PilotRegistrationComponent } })
export default class BlogCardSubscribe extends Mixins(ContentComponent) {
  @baseModule.Action(BaseActionTypes.RegisterPilot)
  RegisterPilot!: (request: PilotRegistration) => void;

  @baseModule.Getter(BaseGetterTypes.PilotRegistration)
  pilotRegistration!: ApiRequest<PilotRegistration>;

  constructor() {
    super();
    this.componentName = "pilot";
  }
}
