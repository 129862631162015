



























import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import ContentComponent from "@/components/ContentComponent";
import VueMarkdown from "vue-markdown";

@Component({ components: { VueMarkdown } })
export default class WhatsApp extends Mixins(ContentComponent) {
  constructor() {
    super();
    this.componentName = "contact-whatsapp";
  }
}
