























































































































import { Component, Vue, Mixins } from "vue-property-decorator";
import ContentComponent from "@/components/ContentComponent";

import { namespace } from "vuex-class";
const mentorModule = namespace("mentors");
import { MentorActionTypes, MentorGetterTypes } from "@/store/mentors/types";

import { ApiRequest } from "@/store/types";
import { Content, Mentor } from "@/models";

import VueMarkdown from "vue-markdown";
import MentorCarousel from "@/components/MentorCarousel.vue";

import Kennisvrager from "@/assets/kennisvrager.svg";
import Kennisgever from "@/assets/kennisgever.svg";
import MeerZien from "@/assets/meerzien.svg";

@Component({
  components: {
    VueMarkdown,
    MentorCarousel,
    Kennisgever,
    Kennisvrager,
    MeerZien,
  },
})
export default class Home extends Mixins(ContentComponent) {
  constructor() {
    super();
    this.componentName = "homepage";
  }

  // Mentors
  @mentorModule.Action(MentorActionTypes.LoadMentors)
  LoadMentors!: () => void;

  @mentorModule.Getter(MentorGetterTypes.Mentors)
  mentors!: ApiRequest<Mentor[]>;

  // Server-side only
  // This will be called by the server renderer automatically
  async serverPrefetch() {
    // return the Promise from the action
    // so that the component waits before rendering
    return this.LoadMentors();
  }

  mounted() {
    if (!this.mentors.value) {
      this.LoadMentors();
    }
  }
}
