












import { Component, Prop, Vue } from "vue-property-decorator";
import { Mentor } from "@/models";
import MentorCard from "@/components/MentorCard.vue";
import AskQuestionCard from "@/components/AskQuestionCard.vue";

function shuffleArray<T>(array: T[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}
@Component({ components: { MentorCard, AskQuestionCard } })
export default class MentorCarousel extends Vue {
  @Prop() mentors!: Mentor[];

  get selectedMentors(): Mentor[] {
    if (this.mentors) {
      const shuffledMentors = [...this.mentors];
      shuffleArray(shuffledMentors);
      return shuffledMentors.slice(0, 3);
    }
    return [];
  }
}
