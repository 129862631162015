




















































































































































import { Component, Vue, Mixins } from "vue-property-decorator";
import ContentComponent from "@/components/ContentComponent";

import VueMarkdown from "vue-markdown";

import Logo from "@/assets/logo.svg";
@Component({ components: { Logo, VueMarkdown } })
export default class HowItWorks extends Mixins(ContentComponent) {
  constructor() {
    super();
    this.componentName = "how it works";
  }
}
